import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Layout from "../components/momentum/layout.module.scss";
import Hero from "../images/momentum/hero.jpg";
import Footer from "../components/footer";
import Metatags from "../components/metatags";

require('typeface-open-sans');

require('typeface-cardo');

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Metatags title="Magazine Layout | May 17 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-17.png" url="https://cssgrid31.brett.cool" pathname="/may-17" mdxType="Metatags" />
    <div className={Layout.container}>
      <div className={Layout.header}>
        <h1>{`Keeping up the momentum`}</h1>
      </div>
      <div className={Layout.photo}><img src={Hero} /></div>
      <div className={Layout.content}><p><span className="sc">we are now</span> just over half-way through May, and as such, this CSS Grid a Day challenge.</p>
        <p>{`While this project has been tiring, it’s also been very rewarding, and while the original goal was to learn how to use CSS Grid, I’ve learned a couple of important themes.`}</p>
        <h2>{`Deadlines guide focus`}</h2>
        <p>{`Perfectionism and procastination are two traits that together really stymie progress.`}</p>
        <p>{`When something I’m working on just isn’t `}<em parentName="p">{`coming together`}</em>{`—whether it’s a personal project, or trying to learn something new—it’s far too easy to let myself off the hook, and tell myself `}<em parentName="p">{`I’ll do it later`}</em>{` when there’s no external deadline.`}</p>
        <p>{`Although arbitrary, self-imposed deadlines don’t actually matter, it provides me with enough motivation to push through.`}</p>
        <p>{`Self-imposed deadlines also have no consequence by definition, and that has allowed me to be more ruthless with reducing scope in order to complete an entry—a luxury I can’t afford elsewhere.`}</p>
        <h2>{`Reinforce with repetition`}</h2>
        <p>{`In the past few months, I’ve spun up several Gatsby-based websites for different projects. On each subsequent project, I get more familiar with the development lifecycle, learn new techniques, and strengthen and reinforce my current understanding.`}</p>
        <p>{`The same applies to design too. By trying to design something different each day, it provides a brand new opportunity to learn new design techniques, and reinforce what I’ve learned.`}</p>
        <h2>{`Looking forward`}</h2>
        <p>{`On the weekend, I’ll be traveling to Melbourne for the rest of the month for work. `}</p>
        <p>{`This is going to disrupt my  schedule, and it remains to be seen how it may affect the last 12 days of the CSS Grid project!`}</p>
        <p>{`Nevertheless, I’m going to try my best to stick to my target of a Grid a Day—thank you for joining me on this project so far!`}</p>
      </div>
    </div>
    <Footer date={17} prev={true} next={true} mdxType="Footer">
      <p>{`Just over halfway through now! (Okay technically that was yesterday). Hard to believe it! This month (and year) has just flown by.`}</p>
      <p>{`I have more thoughts on this particular topic. Part of the problem with these entries is not so much the design or coding part, but also, the content. I have a vague idea of what I want to be able to write, but unfortunately, unlike design and code, writing doesn’t really work for me under tight deadline conditions.`}</p>
      <p>{`Although definitely not sustainable indefinitely, these kind of design and code exercises are very much equivalent to any exercise, and keeping up motivation and making it a habit are the best way to see results.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      